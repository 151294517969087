export const FAQ = {
  ru: [{
    question: 'Как подать заявку для участия в конкурсе?',
    answer: 'Заявки на участие в конкурсе в качестве участника подаются в соответствующем разделе сайта «Заявки».',
    isOpen: true
  },  {
    question: 'Когда и каким способом получим наборы данных?',
    answer: 'Датасеты будут направлены на Вашу корпоративную почту команды в течении дня 21 октября т.г.',
    isOpen: false
  }, {
    question: 'Какие темы для исследования могу выбрать?',
    answer: 'Выбор темы свободный. Вместе с тем, проект должен решать определенные социально-экономические вызовы и в работах  обязательно применение наборов синтетических данных, представленных  Организаторами.',
    isOpen: false
  }, {
    question: 'Что подразумевает собой наборы синтетических данных?',
    answer: 'Наборы синтетические данных - это программно сгенерированные данные на основе фактических данных и учитывающие их паттерны и распределение, при этом обеспечивая конфиденциальность данных',
    isOpen: false
  }, {
    question: 'Где будет проходить финал?',
    answer: 'Финал будет проходить в offline формате на платформе Astana Hub.\n' +
      'Место проведения: г.Астана, проспект Мәңгілік ел, 55/8, Блок С 4.6, 1 этаж, Event Hall.',
    isOpen: false
  }],
  kk: [{
    question: 'Конкурсқа өтінімді қалай беруге болады?',
    answer: 'Конкурсқа қатысушы ретінде өтінімдер «Өтінімдер» сайтының тиісті бөлімі арқылы беріледі.',
    isOpen: true
  },  {
    question: 'Деректер жинағын қашан және қалай аламыз?',
    answer: 'Деректер жинағы ағымдағы жылдың 21 қазанында бір күн ішінде команданың корпоративтік поштасына жіберіледі.',
    isOpen: false
  }, {
    question: 'Зерттеу үшін қандай тақырыптарды таңдауға болады?',
    answer: 'Тақырыпты таңдау еркін түрде. Сонымен қатар,   белгілі әлеуметтік-экономикалық сын-тегеуіріндерді шешуі тиіс және жұмыстарда ұйымдастырушылар ұсынған синтетикалық деректер жинағының біреуі қолданылуы керек.',
    isOpen: false
  }, {
    question: 'Синтетикалық деректер жинағы нені білдіреді?',
    answer: 'Синтетикалық деректер жинақтары – бұл деректердің құпиялылығын қамтамасыз ете отырып, олардың үлгілері мен таралуын ескере отырып, нақты деректерге негізделген бағдарламалық түрде жасалған деректер.',
    isOpen: false
  }, {
    question: 'Финал қай жерде өтеді?',
    answer: 'Финал Astana Hub платформасында offline форматында өтеді.\n' +
      'Өтетін орны: Астана қаласы, Мәңгілік ел даңғылы, 55/8, С блогы 4.6, 1 қабат, Event Hall.',
    isOpen: false
  }]
};

export interface ITable {
  ru: ITableRow[];
  kk: ITableRow[];
}

export interface ITableRow {
  number: number;
  title: string;
  date: string;
  items: string[];
}

export const TABLE: ITable = {
  ru: [{
    number: 1,
    title: 'Объявление конкурса',
    date: '14.10.2024',
    items: [
      'рассылка приглашений заинтересованным сторонам и широкой аудитории в социальных сетях;',
      'размещение информации об объявлении конкурса в социальных сетях.'
    ]
  }, {
    number: 2,
    title: 'Запуск конкурса',
    date: '14.10.2024',
    items: [
    ]
  }, {
    number: 3,
    title: 'Подача заявок',
    date: '14-18.10.2024',
    items: [
      'заполнение онлайн-формы на сайте datathon.stat.gov.kz командами.'
    ]
  }, {
    number: 4,
    title: 'Онлайн открытие конкурса',
    date: '21.10.2024',
    items: []
  }, {
    number: 5,
    title: 'Основная часть',
    date: '',
    items: [
    ]
  }, {
    number: null,
    title: 'Оглашение ключевых задач необходимых к решению в ходе конкурса, предоставление доступа к данным',
    date: '21.10.2024',
    items: [
    ]
  }, {
    number: null,
    title: 'Подготовка командами конкурсного проекта',
    date: '',
    items: [
    ]
  }, {
      number: null,
      title: null,
      date: '24.10.2024',
      items: [
        'Результат 1 (2-3 слайда): Дескриптивная аналитика датасетов (2-3 слайда)'
      ]
    }, {
    number: null,
    title: null,
    date: '28.10.2024',
    items: [
      'Workshop 1. Промежуточные встречи с командами в формате онлайн'
    ]
  }, {
    number: null,
    title: null,
    date: '29-31.10.2024',
    items: [
      'Формирование гипотез, проработка методологии, тестирование гипотез командами'
    ]
  }, {
    number: null,
    title: null,
    date: '01.11.2024',
    items: [
      'Результат 2. Описание гипотез, сформированные первичные решения (2-3 слайда)'
    ]
  }, {
    number: null,
    title: null,
    date: '04.11.2024',
    items: [
      'Workshop 2. Промежуточные встречи с командами в формате онлайн'
    ]
  }, {
    number: null,
    title: null,
    date: '05-07.11.2024',
    items: [
      'Работа над гипотезами, завершение проекта'
    ]
  }, {
    number: null,
    title: 'Сдача финальных работ',
    date: '08.11.2024',
    items: []
  }, {
      number: 6,
      title: 'Допуск к финалу',
      date: '11-13.11.2024',
      items: [
        'Проверка работ Организаторами на соответствие минимальным требованиям:',
        'соответствие работ целям проекта',
        'применение представленных датасетов',
        'использование инструментов анализа и визуализации данных',
        'презентация проекта (сведения о работе, авторы проекта, детали проекта, выводы).'
      ]
    }, {
    number: 7,
    title: 'Финал',
    date: '14.11.2024',
    items: [
      'презентация и защита проектных работ в режиме online/offline по выбору команды (регламент до 15-минут)',
      'сессия вопросов и ответов между жюри и участниками',
      'подведение итогов оценки жюри',
      'торжественное награждение победителей'
    ]
  }],
  kk: [{
    number: 1,
    title: 'Конкурсты туралы хабарландыру',
    date: '14.10.2024',
    items: [
      'қатысуға мүдделі тараптарға және кең аудиторияға әлеуметтік желілер арқылы шақырулар жіберу;',
      'әлеуметтік желілерде конкурсты хабарландыру туралы ақпаратты жариялау.'
    ]
  }, {
    number: 2,
    title: 'Конкурстың басталуы',
    date: '14.10.2024',
    items: [
    ]
  }, {
    number: 3,
    title: 'Өтінім беру',
    date: '14-18.10.2024',
    items: [
      'командалардың datathon.stat.gov.kz сайтында онлайн-нысандарды толтыруы.'
    ]
  }, {
    number: 4,
    title: 'Конкурстың онлайн ашылуы',
    date: '21.10.2024',
    items: []
  }, {
    number: 5,
    title: 'Негізгі бөлім',
    date: '',
    items: [
    ]
  }, {
    number: null,
    title: 'Конкурс барысында шешу қажет негізгі міндеттерді жария ету, деректерге қолжетімділікті ұсыну',
    date: '21.10.2024',
    items: [
    ]
  }, {
    number: null,
    title: 'Командалардың конкурстық жобаны дайындауы',
    date: '',
    items: [
    ]
  }, {
    number: null,
    title: null,
    date: '24.10.2024',
    items: [
      '1-нәтиже. Деректер жинағының дескриптивті аналитикасы (2-3 слайд)'
    ]
  }, {
    number: null,
    title: null,
    date: '28.10.2024',
    items: [
      '1-workshop. Командалармен онлайн форматтағы аралық кездесулер'
    ]
  }, {
    number: null,
    title: null,
    date: '29-31.10.2024',
    items: [
      'Гипотезаларды қалыптастыру, әдіснаманы пысықтау. Командалардың гипотезаларды тестілеуі'
    ]
  }, {
    number: null,
    title: null,
    date: '01.11.2024',
    items: [
      '2-нәтиже. Гипотезалардың сипаттамасы, қалыптасқан бастапқы шешімдер (2-3 слайд)'
    ]
  }, {
    number: null,
    title: null,
    date: '04.11.2024',
    items: [
      '2-workshop. Командалармен онлайн форматтағы аралық кездесулер'
    ]
  }, {
    number: null,
    title: null,
    date: '05-07.11.2024',
    items: [
      'Гипотезалар бойынша жұмыс, жобаларды аяқтау'
    ]
  }, {
    number: null,
    title: 'Қорытынды жұмыстарды тапсыру',
    date: '08.11.2024',
    items: []
  }, {
    number: 6,
    title: 'Финалға өткізу',
    date: '11-13.11.2024',
    items: [
      'Ұйымдастырушының жұмыстарды келесі минималды талаптарға сәйкестігін тексеруі:',
      'жұмыстардың жоба мақсаттарына сәйкестігі',
      'ұсынылған деректер жинағын қолдануы',
      'деректерді талдау және визуализация құралдарын пайдалануы',
      'жоба презентациясы (жоба туралы мәліметтер, жоба авторлары, жобаның негізгі тұстары, қорытындылар).'
    ]
  }, {
    number: 7,
    title: 'Финал',
    date: '14.11.2024',
    items: [
      'Команданың таңдауы бойынша online/offline түрде жоба жұмыстарын презентациялау және қорғау (регламент 15 минутқа дейін)',
      'Қазылар алқасы мен қатысушылар арасындағы сұрақ-жауап сессиясы',
      'Қазылар алқасының бағалауы бойынша қорытынды шығаруы',
      'Жеңімпаздарды салтанатты түрде марапаттау'
    ]
  }]
};
