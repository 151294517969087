import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ViewportScroller} from '@angular/common';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../../data.service';
import {MessagingService} from '../../../../../shared/services/messaging.service';
import {AlertMessage} from '../../../../../alerts';
import {FAQ, ITable, TABLE} from './data';
import {MatTable} from '@angular/material/table';
import {MentorModel, PartyModel} from '../../../../../shared/models/mentor';


@Component({
  selector: 'app-landing-2024',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class Landing3Component implements OnInit {


  constructor(
    private viewportScroller: ViewportScroller,
    private translate: TranslateService,
    private dataService: DataService,
    private messaging: MessagingService
  ) {
  }

  table: ITable = TABLE;
  faq = FAQ;

  cDateMillisecs: any;
  tDateMillisecs: any;
  currentDate: any;
  targetDate: any;
  difference: any;
  seconds: any;
  minutes: any;
  hours: any;
  days: any;

  public lang: 'ru' | 'kk' = 'kk';

  nav: boolean;

  form = 3;
  rules = false;

  form1 = new FormGroup({
    full_name: new FormControl('', [Validators.required]),
    work_place: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    short_description: new FormControl('', [Validators.required]),
    contact_data: new FormControl('', [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
  });

  form2 = new FormGroup({
    org_name: new FormControl('', [Validators.required]),
    full_name: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    sponsorship: new FormControl('', [Validators.required]),
    contact_data: new FormControl('', [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
  });

  form3 = new FormGroup({
    name: new FormControl('', [Validators.required]),
    educationalPlace: new FormControl('', [Validators.required]),
    isEducation: new FormControl('', []),
    isWorker: new FormControl('', []),
    city: new FormControl('', [Validators.required]),
    contact_data: new FormControl('', [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
  });


  partyMemberForm = new FormGroup({
    photoBase64: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    programm: new FormControl('', [Validators.required]),
    education: new FormControl('', [Validators.required]),
    birthday: new FormControl('', [Validators.required]),
    fileBase64: new FormControl('', [Validators.required])
  });

  partyMembersDisplayedColumns: string[] = ['name', 'action'];
  partyMembers: any[] = [];
  mentors: MentorModel[] = [];
  parties: PartyModel[] = [];
  version = 3;


  @ViewChild(MatTable) tablePartyMembers: MatTable<any>;
  @ViewChild('photoBase64') memberPhotoFile: ElementRef;
  @ViewChild('fileBase64') memberEducationFile: ElementRef;
  isEducation = true;
  isWorker = false;
  isIP = false;
  isFormActive = false;

  ngOnInit(): void {
    this.myTimer();
    const lang = localStorage.getItem('lang');
    if (lang) {
      this.setLang(lang);
    }
    this.translate.setDefaultLang('kk');
    this.getMentors();
    this.getParties();
  }

  onClick(elementId: string): void {
    this.nav = false;
    this.viewportScroller.scrollToAnchor(elementId);
  }

  goToForm() {
    this.form = 3;
    this.viewportScroller.scrollToAnchor('forms');
  }

  bePartnerGo() {
    this.form = 2;
    this.viewportScroller.scrollToAnchor('forms');
  }

  setLang(lang: any) {
    this.lang = lang;
    this.translate.use(lang);
    localStorage.setItem('lang', this.lang);
  }

  myTimer() {
    this.currentDate = new Date();
    this.targetDate = new Date(2024, 10, 14, 0);
    if (this.currentDate > this.targetDate){
      document.getElementById('days').innerText = '00';
      document.getElementById('hours').innerText = '00';
      document.getElementById('mins').innerText = '00';
    }
    else{
      this.cDateMillisecs = this.currentDate.getTime();
      this.tDateMillisecs = this.targetDate.getTime();
      this.difference = this.tDateMillisecs - this.cDateMillisecs;
      this.seconds = Math.floor(this.difference / 1000);
      this.minutes = Math.floor(this.seconds / 60);
      this.hours = Math.floor(this.minutes / 60);
      this.days = Math.floor(this.hours / 24);
      this.hours %= 24;
      this.minutes %= 60;
      this.hours = this.hours < 10 ? '0' + this.hours : this.hours;
      this.minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes;
      document.getElementById('days').innerText = this.days;
      document.getElementById('hours').innerText = this.hours;
      document.getElementById('mins').innerText = this.minutes;
      setInterval(this.myTimer, 1000);
    }
  }

  sendForm1() {
    if (!this.form1.valid) {
      this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Заполните форму' : 'Өтінімді толтырыңыз', [], true, true));
      return;
    }
    if (!this.rules) {
      this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Ознакомьтесь с правилами проведения конкурса' : 'Конкурсты өткізу ережесімен танысыңыз', [], true, true));
      return;
    }
    const obj = this.form1.getRawValue();
    obj.version = this.version;
    this.dataService.setForm1(obj).then(response => {
      this.form1.reset();
      this.rules = false;
      this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Заявка успешно отправлена!' : 'Өтінім сәтті жіберілді', [], true));
    });
  }

  sendForm2() {
    if (!this.form2.valid) {
      this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Заполните форму' : 'Өтінімді толтырыңыз', [], true, true));
      return;
    }
    if (!this.rules) {
      this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Ознакомьтесь с правилами проведения конкурса' : 'Конкурсты өткізу ережесімен танысыңыз', [], true, true));
      return;
    }
    const obj = this.form2.getRawValue();
    obj.version = this.version;
    this.dataService.setForm2(obj).then(response => {
      this.form2.reset();
      this.rules = false;
      this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Заявка успешно отправлена!' : 'Өтінім сәтті жіберілді', [], true));
    });
  }

  sendForm3() {
    if (!this.form3.valid) {
      this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Заполните форму' : 'Өтінімді толтырыңыз', [], true, true));
      return;
    }
    if (!this.rules) {
      this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Ознакомьтесь с правилами проведения конкурса' : 'Конкурсты өткізу ережесімен танысыңыз', [], true, true));
      return;
    }
    if (this.partyMembers.length < 2 || this.partyMembers.length > 5) {
      // tslint:disable-next-line:max-line-length
      this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Количество участников от 2 до 5' : 'Қатысушылар саны 2-ден 5-ке дейін', [], true, true));
      return;
    }
    const obj = this.form3.getRawValue();
    obj.version = this.version;
    obj.members = this.partyMembers;
    this.dataService.setForm3(obj).then(response => {
      this.form3.reset();
      this.isEducation = true;
      this.partyMembers = [];
      this.rules = false;
      this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Заявка успешно отправлена!' : 'Өтінім сәтті жіберілді', [], true));
    });
  }
  addPartyMember() {
    console.log(this.partyMemberForm.controls.fileBase64.value);
    if (!this.partyMemberForm.valid) {
      // tslint:disable-next-line:max-line-length
      this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Заполните форму участника' : 'Қатысушы формасын толтырыңыз', [], true, true));
      return;
    }
    if (this.partyMemberForm.controls.fileBase64.value === '' || this.partyMemberForm.controls.fileBase64.value === null){
      this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Загрузите документ' : 'Документi тіркеңіз', [], true, true));
      return;
    }
    const birthdate = new Date(this.partyMemberForm.controls.birthday.value);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - birthdate.getFullYear();
    if (age > 35){
      this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Уважаемый кандидат, принять участие в конкурсе могут граждане Республики Казахстан в возрасте до 35 лет (включительно)\n' : 'Құрметті кандидат, конкурсқа 35 жасқа дейінгі (қоса алғанда) Қазақстан Республикасының азаматтары қатыса алады', [], true, true));
      return;
    }
    const obj = this.partyMemberForm.getRawValue();
    this.partyMembers.push(obj);
    this.memberPhotoFile.nativeElement.value = '';
    this.memberEducationFile.nativeElement.value = '';
    this.partyMemberForm.reset();
    this.tablePartyMembers.renderRows();
  }

  removePartyMember(i: number) {
    this.partyMembers.splice(i, 1);
    this.tablePartyMembers.renderRows();
  }

  memberPhotoChange($event){
    const reader = new FileReader();
    reader.readAsDataURL($event.target.files[0]);
    reader.onload = () => {
      this.partyMemberForm.controls.photoBase64.setValue(reader.result);

    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }
  memberFileChange($event){
    const reader = new FileReader();
    reader.readAsDataURL($event.target.files[0]);
    reader.onload = () => {
      this.partyMemberForm.controls.fileBase64.setValue(reader.result);

    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }


  downloadBNSDatasets() {
    if (this.lang === 'ru'){
      this.download('assets/2024/data_ru.rar', 'Образец + Пояснение.rar');
    }
    else{
      this.download('assets/2024/data_kk.rar', 'Үлгі + Түсіндірме.rar');
    }
  }

  downloadCPTFDatasets() {
    // if (this.lang === 'ru'){
    //   this.download('assets/datasetFiles/cpft-ru.rar', 'Пояснение(ЦРПФТ).rar');
    // }
    // else{
    //   this.download('assets/datasetFiles/cpft-kaz.rar', 'Түсіндірме(ЦРПФТ).rar');
    // }
  }

  download(url, filename) {
    fetch(url).then((t) => {
      return t.blob().then((b) => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(b);
          a.setAttribute('download', filename);
          a.click();
        }
      );
    });
  }

  private getMentors() {
    this.dataService.getMentors(this.version).then(resp => {
      this.mentors = resp;
    });
  }

  private getParties() {
    this.dataService.getParties(this.version).then(resp => {
      resp.sort((a, b) => (a.peopleVoice < b.peopleVoice ? -1 : 1));
      this.parties =
        // resp;
         resp.filter(z => z.peopleVoice > 0);
    });
  }

  openOldDatathon(year: number) {
    window.open('/#/' + year);
  }
}
