<div class="section section-header" id="header">
  <div class="wrapper">
    <div class="left">
      <div class="logo-1">
        <img [src]="lang == 'ru' ? '/assets/2024/judge/1.png' : '/assets/2024/judge/1.png'" alt=""/>
                <div [innerHTML]="'judge.text_3'|translate">
                </div>
      </div>
      <div class="logo-2">
        <img [src]="lang == 'ru' ? '/assets/2024/creators/header-2.png' : '/assets/2024/creators/header-2.png'" alt=""/>
<!--                <div style="color: #c6ac77eb">-->
<!--                  Ел үміті-->
<!--                </div>-->
      </div>

    </div>
    <div class="right">
      <div class="langGroup">
        <div class="lang" (click)="setLang('kk')" [ngClass]="lang == 'kk' ? 'active' : ''">
          КАЗ
        </div>
        <div class="lang" (click)="setLang('ru')" [ngClass]="lang == 'ru' ? 'active' : ''">
          РУС
        </div>
      </div>
      <div class="item" (click)="onClick('contacts')">{{'contacts.title'|translate}}</div>
      <div class="item" (click)="openOldDatathon(2023)">Дататон 2023</div>
      <div class="item" (click)="goToForm()" *ngIf="isFormActive">{{'form.btn'|translate}}</div>
      <div class="button" (click)="nav = true;">
        <span class="icon-menu"></span>
      </div>
    </div>
  </div>
</div>


<div class="section section-splash">
  <div class="video-wrapper">
    <video playsinline autoplay muted loop id="bg" #video
           (canplay)="video.play()"
           (loadedmetadata)="video.muted = true">
      <source [src]="'/assets/images/data/earth.mp4'" type="video/mp4">
    </video>
  </div>
  <div class="overlayer"></div>
  <div class="wrapper">
    <div class="logo">
      <img [src]="'/assets/images/data/logo.svg'" alt=""/>
    </div>
    <div class="infos">
      <div class="info">
        {{'splash.text_1'|translate}}
      </div>
      <div class="info">
        {{'splash.text_2'|translate}}
      </div>
    </div>
  </div>
  <div class="actions" *ngIf="isFormActive">
    <div class="btn-outer">
<!--      <div class="btn" (click)="onClick('parties')">{{'splash.button'|translate}}</div>-->
      <div class="btn" (click)="goToForm()">{{'form.btn'|translate}}</div>
    </div>
    <!--    <div class="tip" [innerHTML]="'header.tip'|translate"></div>-->
  </div>
</div>


<div class="section section-team">
  <div class="wrapper">
    <div class="section-title">
      {{'team.title'|translate}}
    </div>
    <div class="section-body">
      <div class="teams">
        <div class="team">
          <div class="team__image">
            <img [src]="'/assets/images/data/team-1.svg'" alt=""/>
          </div>
          <div class="team__desc" [innerHTML]="'team.text_4'|translate"></div>
        </div>
        <div class="team">
          <div class="team__image">
            <img [src]="'/assets/images/data/team-3.png'" alt=""/>
          </div>
          <div class="team__desc" [innerHTML]="'team.text_5'|translate"></div>
        </div>
<!--        <div class="team">-->
<!--          <div class="team__image">-->
<!--            <img [src]="'/assets/images/data/team-3.svg'" alt=""/>-->
<!--          </div>-->
<!--          <div class="team__desc" [innerHTML]="'team.text_3'|translate"></div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</div>


<div class="section section-steps">
  <div class="wrapper">
    <div class="section-title">
      {{'steps.title'|translate}}
    </div>
    <div class="section-body">
      <img width="100%" [src]="'/assets/2024/steps_'+lang+'.jpg'">
    </div>
  </div>
</div>


<div class="section section-data">
  <div class="wrapper">
    <div class="section-title">
      {{'data.title'|translate}}
    </div>
    <div class="section-desc">
      {{'data.desc'|translate}}
    </div>
    <div class="section-body">
      <div class="blocks">
        <div class="block">
          <div class="block__source">
            {{'data.source'|translate}}:
          </div>
<!--          <div class="block__title">-->
<!--            {{'data.no_data'|translate}}-->
<!--          </div>-->
          <div class="block__title">
            {{'data.data_5_title'|translate}}
          </div>
          <div class="block__body">
            <p>
              <strong>{{'data.data_source'|translate}}:</strong> {{'data.data_5_source'|translate}}
            </p>
            <p>
              <strong>{{'data.data_area'|translate}}:</strong> {{'data.data_5_area'|translate}}
            </p>
            <p><strong>{{'data.data_period'|translate}}:</strong> 2019-2023</p>
          </div>
<!--          <div class="block__download">-->
<!--            <div class="btn" (click)="downloadBNSDatasets()">-->
<!--              <span class="icon-download"></span>  {{'data.download'|translate}}-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="block">
          <div class="block__source">
            {{'data.source'|translate}}:
          </div>
<!--          <div class="block__title">-->
<!--            {{'data.no_data'|translate}}-->
<!--          </div>-->
          <div class="block__title">
            {{'data.data_4_title'|translate}}
          </div>
          <div class="block__body">
            <p>
              <strong>{{'data.data_source'|translate}}:</strong> {{'data.data_4_source'|translate}}
            </p>
            <p><strong>{{'data.data_area'|translate}}:</strong> {{'data.data_4_area'|translate}}</p>
            <p><strong>{{'data.data_period'|translate}}:</strong> 2018-2024</p>
          </div>
<!--          <div class="block__download">-->
<!--            <div class="btn" (click)="downloadCPTFDatasets()">-->
<!--              <span class="icon-download"></span> {{'data.download'|translate}}-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>


<div class="section section-evaluate">
  <div class="wrapper">
    <div class="section-title">
      {{'evaluate.title'|translate}}
    </div>
    <div class="section-body">
      <div class="scores">
        <div class="score">
          <div class="score__image">
            <img [src]="'/assets/images/data/e-2.svg'" alt=""/>
          </div>
          <div class="score__desc">
            {{'evaluate.text_5'|translate}}
          </div>
        </div>
        <div class="score">
          <div class="score__image">
            <img [src]="'/assets/images/data/e-4.svg'" alt=""/>
          </div>
          <div class="score__desc">
            {{'evaluate.text_6'|translate}}
          </div>
        </div>
        <div class="score">
          <div class="score__image">
            <img [src]="'/assets/images/data/e-3.svg'" alt=""/>
          </div>
          <div class="score__desc">
            {{'evaluate.text_7'|translate}}
          </div>
        </div>
        <div class="score">
          <div class="score__image">
            <img [src]="'/assets/images/data/e-1.svg'" alt=""/>
          </div>
          <div class="score__desc">
            {{'evaluate.text_8'|translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="section section-judge">
  <div class="wrapper">
    <div class="wrapper">
      <div class="section-title">
        {{'judge.title'|translate}}
      </div>
      <div class="section-body">
        <div class="judges">
          <div class="judge">
            <div class="judge__image">
              <img [src]="'/assets/2024/judge/4.png'" alt=""/>
            </div>
            <div class="judge__desc">
              {{'judge.text_1'|translate}}
            </div>
          </div>
          <div class="judge">
            <div class="judge__image">
              <img [src]="'/assets/2024/judge/1.png'" alt=""/>
            </div>
<!--            <div class="judge__desc">-->
<!--              {{'judge.text_3'|translate}}-->
<!--            </div>-->
            <div class="judge__desc" [innerHTML]="'judge.text_3'|translate">
            </div>
          </div>
          <div class="judge">
            <div class="judge__image">
              <img [src]="'/assets/2024/judge/2.png'" alt=""/>
            </div>
            <div class="judge__desc">
              {{'judge.text_6'|translate}}
            </div>
          </div>
          <div class="judge">
            <div class="judge__image">
              <img [src]="'/assets/2024/judge/3.png'" alt=""/>
            </div>
            <div class="judge__desc">
              {{'judge.text_7'|translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="section section-presents">
  <div class="wrapper">
    <div class="section-title">
      {{'present.title'|translate}}
    </div>
    <div class="section-body">
      <div class="blocks">
        <div class="block">
          <div class="block__image">
            <img [src]="'/assets/images/data/p-1.svg'" alt="present"/>
          </div>
          <div class="block__desc">
            {{'present.text_1'|translate}}
          </div>
        </div>
        <div class="block">
          <div class="block__image">
            <img [src]="'/assets/images/data/p-6.png'" alt="present"/>
          </div>
          <div class="block__desc">
            {{'present.text_6'|translate}}
          </div>
        </div>
        <div class="block">
          <div class="block__image">
            <img [src]="'/assets/images/data/p-3.svg'" alt="present"/>
          </div>
          <div class="block__desc">
            {{'present.text_4'|translate}}
          </div>
        </div>

      </div>
    </div>
  </div>
</div>


<div class="section section-creators">
  <div class="wrapper">
    <div class="wrapper">
      <div class="section-title">
        {{'creators.title'|translate}}
      </div>
      <div class="section-body">
        <div class="blocks">
          <div class="block">
            <div class="block__image">
              <img [src]="'/assets/2024/creators/1.png'" alt="judge"/>
            </div>
            <div class="block__desc">
              {{'creators.text_1'|translate}}
            </div>
          </div>
          <div class="block">
            <div class="block__image">
              <img [src]="'/assets/2024/creators/2.png'" alt="judge"/>
            </div>
            <div class="block__desc">
              {{'creators.text_3'|translate}}
            </div>
          </div>
          <div class="block">
            <div class="block__image">
              <img [src]="'/assets/2024/creators/3.png'" alt="judge"/>
            </div>
            <div class="block__desc">
              {{'creators.text_4'|translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="section section-countdown">
  <div class="wrapper">
    <div class="section-title">
      {{'countdown.title3'|translate}}:
    </div>
    <div class="section-timer">

      <div class="block">
        <div>
          <p id="days"></p>
        </div>
        <span>{{'countdown.days'|translate}}</span>
      </div>
      <div class="block">
        <div>
          <p id="hours"></p>
        </div>
        <span>{{'countdown.hours'|translate}}</span>
      </div>
      <div class="block">
        <div>
          <p id="mins"></p>
        </div>
        <span>{{'countdown.minutes'|translate}}</span>
      </div>
    </div>
    <div class="section-body">
      <div class="section-title">
        {{'table.title'|translate}}
      </div>
      <table>
        <thead>
        <tr>
          <th>№</th>
          <th>{{'table.steps'|translate}}</th>
          <th>{{'table.times'|translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let t of table[lang]; let i = index">
          <td [ngClass]="!t.number ? 'no' : ''">{{t.number}}</td>
          <td [ngClass]="!t.number ? 'no' : ''">
            <div class="step-title">{{t.title}}</div>
            <div>
              <ul>
                <li *ngFor="let li of t.items">{{li}}</li>
              </ul>
            </div>
          </td>
          <td>
            {{t.date}}
          </td>
        </tr>
        </tbody>
      </table>
      <div class="actions">
        <a [href]="'assets/2024/Регламент_Дататон_2024_'+lang+'.pdf'" target="_blank" rel="noopener noreferrer">
          {{'table.button'|translate}}
        </a>
      </div>
    </div>
  </div>
</div>


<div class="section section-partners">
  <div class="wrapper">

    <div class="section-title">
      {{'partners.title'|translate}}
    </div>

    <div class="section-body">

      <div class="partners">
        <div class="partner">
          <img [src]="'/assets/2024/partners/partner-1.png'" alt=""/>
        </div>
        <div class="partner">
          <img [src]="'/assets/2024/partners/partner-2.png'" alt=""/>
        </div>
<!--        <div class="partner">-->
<!--          <img [src]="'/assets/2024/partners/partner-3.svg'" alt=""/>-->
<!--        </div>-->
      </div>

<!--      <div class="actions">-->
<!--        <div class="btn-outer">-->
<!--          <div class="btn" (click)="bePartnerGo()">{{'partners.button'|translate}}</div>-->
<!--        </div>-->
<!--      </div>-->

    </div>

  </div>
</div>

<!--нужно отоюразить когда появятся команды-->
<div class="section section-parties" id="parties">
  <div class="wrapper">
    <div class="section-title">
      {{'parties.title'|translate}}
    </div>
    <section class="parties-contain">
      <div class="data-card" *ngFor="let party of parties">
        <h3>{{party.name}}</h3>
        <h4 *ngIf="lang=='ru'">{{party.additionalFields?.educationalPlaceNameRu}}</h4>
        <h4 *ngIf="lang=='kk'">{{party.additionalFields?.educationalPlaceNameKz}}</h4>
        <title *ngIf="party.peopleVoice <=3" class="link-text">{{ party.peopleVoice.toString() + ' ' + ('voice.place' | translate)}}</title>
<!--        <title *ngIf="party.peopleVoice >3" class="link-text">{{ 'voice.voce' | translate}}</title>-->
        <a href="{{'#/party?partyId=' + party.id + '&version=' + this.version}}" class="link-text">
        {{'parties.moreInfo' | translate}}
        </a>
      </div>

    </section>

<!--    <section class="parties-contain" style="padding-top: 10px;">-->
<!--      <a href="assets/Итоги.pdf" target="_blank" style="font-size: 21px">{{'voice.download' | translate}}</a>-->

<!--    </section>-->

  </div>
</div>


<!--<div class="section section-parties" style="padding: 10px 0px;">-->
<!--  <div class="wrapper">-->
<!--    <section class="parties-contain">-->
<!--      <iframe width="1065" height="600" src="https://www.youtube.com/embed/kd8dVUs-FbI"-->
<!--              title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
<!--    </section>-->
<!--  </div>-->
<!--</div>-->


<!--<div class="section section-mentors">-->
<!--  <div class="wrapper">-->
<!--    <div class="section-title">-->
<!--      {{'mentors.title'|translate}}-->
<!--    </div>-->

<!--    <div class="section-body">-->

<!--      <div class="mentors">-->
<!--        <div class="mentor" *ngFor="let f of mentors">-->
<!--          <div class="mentor__title" (click)="f.isOpen = !f.isOpen"-->
<!--               [ngStyle]="{'background': f.partyName != null ? '#DBF4FF' : 'white'}">-->
<!--            <img class="avatar" *ngIf="f.photoUrl!=null" [src]=f.photoUrl><img class="avatar" *ngIf="f.photoUrl==null"-->
<!--                                                                               src="https://datathon.kz/Files/Photo/avatar.jpg">-->
<!--            {{ (this.lang == 'ru' ? f.full_Name : f.full_Name_kz) + (f.partyName != null ? (this.lang == 'ru' ? " - команда " : " - ") + f.partyName + (this.lang == 'kk' ? " командасы" : "") : "") }}-->
<!--            <span *ngIf="!f.isOpen" class="icon-chevron-right"></span>-->
<!--            <span *ngIf="f.isOpen" class="icon-chevron-down"></span>-->
<!--          </div>-->
<!--          <div class="mentor__info" *ngIf="f.isOpen">-->
<!--            <div class="mentor__info__group" *ngIf="lang == 'ru'">-->
<!--              <b>Место работы: </b>   {{ f.work_place}}-->
<!--            </div>-->
<!--            <div class="mentor__info__group" *ngIf="lang == 'ru'">-->
<!--              <b>Город: </b> {{ f.city}}-->
<!--            </div>-->
<!--            <div class="mentor__info__group" *ngIf="lang == 'ru'">-->
<!--              <b>Краткое резюме: </b> {{ f.short_description}}-->
<!--            </div>-->

<!--            <div class="mentor__info__group" *ngIf="lang == 'kk'">-->
<!--              <b>Жұмыс орны: </b> {{  f.work_place_kz}}-->
<!--            </div>-->
<!--            <div class="mentor__info__group" *ngIf="lang == 'kk'">-->
<!--              <b>Қала: </b> {{f.city}}-->
<!--            </div>-->
<!--            <div class="mentor__info__group" *ngIf="lang == 'kk'">-->
<!--              <b>Қысқаша түйіндеме: </b> {{ f.short_description_kz}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--    </div>-->

<!--  </div>-->
<!--</div>-->


<div class="section section-faq">
  <div class="wrapper">

    <div class="section-title">
      {{'faq.title'|translate}}
    </div>

    <div class="section-body">

      <div class="faqs">

        <div class="faq" *ngFor="let f of faq[lang]">
          <div class="faq__question" (click)="f.isOpen = !f.isOpen">
            {{f.question}}
            <span *ngIf="!f.isOpen" class="icon-chevron-right"></span>
            <span *ngIf="f.isOpen" class="icon-chevron-down"></span>
          </div>
          <div class="faq__answer" *ngIf="f.isOpen">
            {{f.answer}}
          </div>
        </div>

      </div>

    </div>

  </div>
</div>

<div class="section section-form" id="forms" *ngIf="isFormActive">
  <div class="wrapper">
    <div class="section-title">
      {{'form.title'|translate}}
    </div>
    <div class="section-body">

      <div class="tabs">
                <div class="tab" (click)="form = 3" [ngClass]="form == 3 ? 'active' : ''">
                  {{"form.form_3.title"|translate}}
                </div>
        <!--        <div class="tab" (click)="form = 1" [ngClass]="form == 1 ? 'active' : ''">-->
        <!--          {{"form.form_1.title"|translate}}-->
        <!--        </div>-->
<!--        <div class="tab" (click)="form = 2" [ngClass]="form == 2 ? 'active' : ''">-->
<!--          {{"form.form_2.title"|translate}}-->
<!--        </div>-->
      </div>

      <div class="forms">
<!--                <div class="form" *ngIf="form == 1">-->
<!--                  <form [formGroup]="form1">-->
<!--                    <div class="label">-->
<!--                      <div class="label__name">{{"form.form_1.fio"|translate}}</div>-->
<!--                      <div class="label__field">-->
<!--                        <label>-->
<!--                          <input class="form-control" formControlName="full_name"/>-->
<!--                        </label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="label">-->
<!--                      <div class="label__name">{{"form.form_1.place"|translate}}</div>-->
<!--                      <div class="label__field">-->
<!--                        <label>-->
<!--                          <input class="form-control" formControlName="work_place"/>-->
<!--                        </label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="label">-->
<!--                      <div class="label__name">{{"form.form_1.city"|translate}}</div>-->
<!--                      <div class="label__field">-->
<!--                        <label>-->
<!--                          <input class="form-control" formControlName="city"/>-->
<!--                        </label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="label">-->
<!--                      <div class="label__name" [innerHTML]="'form.form_1.desc'|translate"></div>-->
<!--                      <div class="label__field">-->
<!--                        <label>-->
<!--                          <textarea class="form-control" rows="4" formControlName="short_description"></textarea>-->
<!--                        </label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="label">-->
<!--                      <div class="label__name">{{"form.contacts"|translate}}</div>-->
<!--                      <div class="label__field">-->
<!--                        <label>-->
<!--                          <input class="form-control" formControlName="contact_data"/>-->
<!--                        </label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="label">-->
<!--                      <div class="label__name">{{"form.email"|translate}}</div>-->
<!--                      <div class="label__field">-->
<!--                        <label>-->
<!--                          <input class="form-control" type="email" formControlName="email"/>-->
<!--                        </label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="label">-->
<!--                      <div class="label__name blue">-->
<!--                        <a [href]="'assets/rules_'+lang+'.pdf'" target="_blank">{{"form.rules"|translate}}</a>-->
<!--                      </div>-->
<!--                      <div class="label__field">-->
<!--                        <app-checkbox [value]="rules" (click)="rules = !rules"></app-checkbox>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="actions">-->
<!--                      <div class="btn-outer">-->
<!--                        <div class="btn" (click)="sendForm1()">{{"form.btn"|translate}}</div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </form>-->
<!--                </div>-->
<!--              <div class="form" *ngIf="form == 2">-->
<!--                <form [formGroup]="form2">-->
<!--                  <div class="label">-->
<!--                    <div class="label__name">{{"form.form_2.org"|translate}}</div>-->
<!--                    <div class="label__field">-->
<!--                      <label>-->
<!--                        <input class="form-control" formControlName="org_name"/>-->
<!--                      </label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="label">-->
<!--                    <div class="label__name">{{"form.form_2.fio"|translate}}</div>-->
<!--                    <div class="label__field">-->
<!--                      <label>-->
<!--                        <input class="form-control" formControlName="full_name"/>-->
<!--                      </label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="label">-->
<!--                    <div class="label__name">{{"form.form_2.address"|translate}}</div>-->
<!--                    <div class="label__field">-->
<!--                      <label>-->
<!--                        <input class="form-control" formControlName="address"/>-->
<!--                      </label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="label">-->
<!--                    <div class="label__name">{{"form.form_2.help"|translate}}</div>-->
<!--                    <div class="label__field">-->
<!--                      <label>-->
<!--                        <input class="form-control" formControlName="sponsorship"/>-->
<!--                      </label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="label">-->
<!--                    <div class="label__name">{{"form.contacts"|translate}}</div>-->
<!--                    <div class="label__field">-->
<!--                      <label>-->
<!--                        <input class="form-control" formControlName="contact_data"/>-->
<!--                      </label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="label">-->
<!--                    <div class="label__name">{{"form.email"|translate}}</div>-->
<!--                    <div class="label__field">-->
<!--                      <label>-->
<!--                        <input class="form-control" type="email" formControlName="email"/>-->
<!--                      </label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="label">-->
<!--                    <div class="label__name blue">-->
<!--                      <a [href]="'assets/rules_'+lang+'.pdf'" target="_blank">{{"form.rules"|translate}}</a>-->
<!--                    </div>-->
<!--                    <div class="label__field">-->
<!--                      <app-checkbox [value]="rules" (click)="rules = !rules"></app-checkbox>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="actions">-->
<!--                    <div class="btn-outer">-->
<!--                      <div class="btn" (click)="sendForm2()">{{"form.btn"|translate}}</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </form>-->
<!--              </div>-->

                <div class="form" *ngIf="form == 3">
                  <form [formGroup]="form3">
                    <div class="label">
                      <div class="label__name">{{"form.form_3.name"|translate}}</div>
                      <div class="label__field">
                        <label>
                          <input class="form-control" formControlName="name"/>
                        </label>
                      </div>
                    </div>
                    <div class="label">
                      <div class="label__name">
                        <div><input [(ngModel)]="isEducation" (ngModelChange)="isWorker=!isEducation" formControlName="isEducation" type="checkbox"><label>{{"form.form_3.educationalPlace"|translate}}</label></div>
                        <div><input [(ngModel)]="isWorker" (ngModelChange)="isEducation=!isWorker" formControlName="isWorker" type="checkbox"><label>{{"form.form_3.workPlace"|translate}}</label></div>
<!--                        <div><input [(ngModel)]="isIP" (ngModelChange)="isEducation=false;isWorker=false" formControlName="isIP" type="checkbox"><label>{{"form.form_3.IP"|translate}}</label></div>-->
                      </div>
                      <div class="label__field">
                        <label>
                          <input class="form-control" formControlName="educationalPlace"/>
                        </label>
                      </div>
                    </div>
                    <div class="label">
                      <div class="label__name">{{"form.form_3.city"|translate}}</div>
                      <div class="label__field">
                        <label>
                          <input class="form-control" formControlName="city"/>
                        </label>
                      </div>
                    </div>
                    <div class="label">
                      <div class="label__name">{{"form.contacts"|translate}}</div>
                      <div class="label__field">
                        <label>
                          <input class="form-control" formControlName="contact_data"/>
                        </label>
                      </div>
                    </div>
                    <div class="label">
                      <div class="label__name">{{"form.email"|translate}}</div>
                      <div class="label__field">
                        <label>
                          <input class="form-control" type="email" formControlName="email"/>
                        </label>
                      </div>
                    </div>
                    <div class="label">
                      <div class="label__name">{{"form.form_3.members"|translate}}</div>
                      <div class="label__field">

                        <div>
                          <form [formGroup]="partyMemberForm">

                            <div class="label_mini">
                              <div class="label__name">{{"form.form_3.fio"|translate}}</div>
                              <div class="label__field">
                                <input formControlName="name" class="memberFormInput">
                              </div>
                            </div>

                            <div class="label_mini">
                              <div class="label__name">{{"form.form_3.birthday"|translate}}</div>
                              <div class="label__field">
                                <input type="date" formControlName="birthday" class="memberFormInput">
                              </div>
                            </div>


                            <div class="label_mini" *ngIf="isEducation">
                              <div class="label__name">{{"form.form_3.programmAll"|translate}}</div>
                              <div class="label__field">
                                <input formControlName="programm" class="memberFormInput">
                              </div>
                            </div>

                            <div class="label_mini">
                              <div class="label__name" *ngIf="isEducation">{{"form.form_3.education"|translate}}</div>
                              <div class="label__name" *ngIf="!isEducation">{{"form.form_3.work"|translate}}</div>
                              <div class="label__field">
                                <input formControlName="education" class="memberFormInput">
                              </div>
                            </div>

                            <div class="label_mini">
                              <div class="label__name" *ngIf="isEducation">{{"form.form_3.file"|translate}}</div>
                              <div class="label__name" *ngIf="!isEducation">{{"form.form_3.workFile"|translate}}</div>
                              <div class="label__field">
                                <input type="file" #fileBase64 (change)="memberFileChange($event)" accept="application/pdf">
                              </div>
                            </div>

                            <div class="label_mini">
                              <div class="label__name">{{"form.form_3.photo"|translate}}</div>
                              <div class="label__field">
                                <input type="file" #photoBase64 (change)="memberPhotoChange($event)"
                                       accept="image/png, image/gif, image/jpeg">
                                <button mat-raised-button (click)="addPartyMember()" class="demo-button">
                                  {{"form.form_3.addMember"|translate}}
                                </button>
                              </div>
                            </div>

                          </form>
                        </div>

                        <div>
                          <table mat-table [dataSource]="partyMembers" *ngIf="partyMembers.length>0"
                                 class="mat-elevation-z8 demo-table">
                            <ng-container matColumnDef="name">
                              <th mat-header-cell *matHeaderCellDef>{{"form.form_3.fio"|translate}}</th>
                              <td mat-cell *matCellDef="let element; let i = index" style="width: 80%">{{element.name}}</td>
                            </ng-container>
                            <ng-container matColumnDef="action">
                              <th mat-header-cell *matHeaderCellDef></th>
                              <td mat-cell *matCellDef="let element; let i = index" style="width: 20%">
                                <button mat-icon-button (click)="removePartyMember(i)" color="warn"
                                        aria-label="Example icon button with a heart icon">
                                  <mat-icon>{{"form.form_3.removeMember"|translate}}</mat-icon>
                                </button>
                              </td>
                            </ng-container>
                            <!--                    <tr mat-header-row *matHeaderRowDef="partyMembersDisplayedColumns"></tr>-->
                            <tr mat-row *matRowDef="let row; columns: partyMembersDisplayedColumns;"></tr>
                          </table>
                        </div>

                      </div>
                    </div>
                    <div class="label">
                      <div class="label__name blue">
                        <a [href]="'assets/2024/Регламент_Дататон_2024_'+lang+'.pdf'" target="_blank">{{"form.rules"|translate}}</a>
                      </div>
                      <div class="label__field">
                        <app-checkbox [value]="rules" (click)="rules = !rules"></app-checkbox>
                      </div>
                    </div>
                    <div class="actions">
                      <div class="btn-outer">
                        <div class="btn" (click)="sendForm3()">{{"form.btn"|translate}}</div>
                      </div>
                    </div>
                  </form>
                </div>
      </div>

    </div>
  </div>
</div>

<div class="section section-footer" id="contacts">
  <div class="wrapper">
    <div class="wrapper-title">{{'contacts.title'|translate}}</div>
    <div class="phones">
      <a href="tel:+77172749301">8 (7172) 74-93-01</a>,
      <a href="tel:+77172749591">74-95-91</a>,
      <a href="tel:+77172749509">74-95-09</a>
    </div>
    <div class="email">
      {{'contacts.email'|translate}}:
      <a href="mailto:qazdatathon@gmail.com">qazdatathon@gmail.com</a>
    </div>
    <div class="toTop" (click)="onClick('header')">
      <span class="icon-arrow-up"></span>
    </div>
  </div>
</div>

<ng-container *ngIf="nav">
  <div class="navigation" (click)="nav = false; $event.stopPropagation()">
    <div class="side">
      <div class="langs">
        <div class="lang" (click)="setLang('ru')" [ngClass]="lang == 'ru' ? 'active' : ''">
          РУС
        </div>
        <div class="lang" (click)="setLang('kk')" [ngClass]="lang == 'kk' ? 'active' : ''">
          КАЗ
        </div>
      </div>
      <div class="menu">
        <div class="item" (click)="onClick('contacts')">{{'contacts.title'|translate}}</div>
        <div class="item" (click)="goToForm()">{{'form.btn'|translate}}</div>
      </div>
    </div>
  </div>
</ng-container>
