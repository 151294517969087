<app-admin></app-admin>
<div class="main-info">
  <table class="styled-table">
    <thead>
    <tr>
      <th>№</th>
      <th>{{'form.form_3.name'|translate}}</th>
      <th>{{'form.form_3.educationalPlace'|translate}}</th>
      <th>{{'form.form_3.city'|translate}}</th>
      <th>{{'form.contacts'|translate}}</th>
      <th>{{'form.email'|translate}}</th>
      <th>{{'form.form_3.members'|translate}}</th>

    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let t of data; let i = index">
      <td>{{t.id}}</td>
      <td>
        {{t.name}}
      </td>
      <td>
        {{t.educationalPlace}}
      </td>
      <td>
        {{t.city}}
      </td>
      <td>
        {{t.contact_data}}
      </td>
      <td>
        {{t.email}}
      </td>
      <td>
        <div *ngFor="let member of t.members">
          <img src="{{member.photo?.path}}" class="memberPhoto" ><b>{{member.name}}</b> {{ " "+  member.programm + " " + member.education + " " + (member.birthDay | date : 'dd-MM-yyyy')}} <a *ngIf="member.file" target="_blank" href="{{member.file?.path}}">{{member.file?.name}}</a>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
  <mat-paginator [length]="20"
                 [pageSize]="20"
                 aria-label="Select page" style="float: left">
  </mat-paginator>
  <button mat-button (click)="export()">Выгрузить</button>
</div>


